import { useEffect, useState } from "react";
import { useUserContext } from "../context/user_context";
import AuthUser from "./AuthUser";
const BatchWiseMachines = (props) => {
  // https://factorynext-back.arthor.com.bd/api/v2/floor-wise-machine-list?factory_id=1&floor_id=1
  const { user, http } = AuthUser();
  const { selectedFactoryId, changeSelectedFactoryId } = useUserContext();
  const [machines, setMachines] = useState([]);
  const [groups, setGroups] = useState([]);
  const [groupsName, setGroupsName] = useState([]);

  useEffect(() => {
    props.selectedFloorId && getMachinse();
  }, [props.selectedFloorId]);

  const getMachinse = async () => {
    const { data } = await http.get(
      `/floor-wise-machine-list?factory_id=${selectedFactoryId}&floor_id=${props.selectedFloorId}`
    );
    const myArray = Object.entries(data).map(([key, value]) => value);
    setMachines(myArray[0]);
    let x = [];
    let y = [];
    if (myArray[0]?.length > 0) {
      myArray[0].map((machine, index) => {
        if (!x.includes(machine.group_id)) {
          x.push(machine.group_id);
        //   setGroups(x);
        }
        if (!y.includes(machine.group)) {
          y.push(machine.group);
        //   setGroupsName(y);
        }
        if(myArray[0].length -1 == index) {
            setGroups(x);
            setGroupsName(y);
        }
      });
    } else {
      setGroups(x);
      setGroupsName(y);
    }

    //   data && setFloors(Object.values(data));
  };

  useEffect(() => {
    // console.log("groups:", groups);
    // groups.map(g=>{
    //     console.log("gp>", g)
    // })
    // console.log("groupsName:", groupsName);
  }, [groups]);

  return (
    <div>
      {groups?.length > 0
        ? groups?.map((group, index) => {
            return (
              <div
                className="row mt-5"
                style={{ maxWidth: "1100px", margin: "0 auto" }}
              >
                <div className="row">
                  <div className="col text-inline">
                    {index == 0 && (
                      <p className="text-dark fw-bold">
                        Batch Wise Machine Activity Log
                      </p>
                    )}
                    <p className="text-dark fw-bold text-end">{groupsName[index]}</p>
                  </div>

                  <div className="col text-end">
                    {machines
                      ? machines[0]?.shift.map((m) => {
                          return (
                            <>
                              <p className="text-dark fw-bold " style={{fontSize:"13px"}}>{m.name}:{m.worker}</p>
                            </>
                          );
                        })
                      : null}
                  </div>
                </div>
                {machines?.map((machine, i) => {
                  if (group === machine.group_id) {
                    return (
                      <div className="col-3 p-1">
                        <div
                          className="p-3"
                          style={{
                            boxShadow: "rgba(0, 0, 0, 0.24) 0px 1px 8px",
                            borderRadius: "10px",
                          }}
                        >
                          <div className="row">
                            <div className="d-flex justify-content-start mt-2 col">
                              <span
                                className={`dot-${machine.color} me-2`}
                              ></span>
                              <span className="">{machine.machineId}</span>
                            </div>
                            <div className="col text-end fw-bold">
                              {machine?.activity?.map((activity, i) => {
                                return (
                                  <span style={{ color: machine.color }}>
                                    {activity}
                                  </span>
                                );
                              })}
                            </div>
                          </div>
                          <div className="row mt-2">
                            <span className="text-center text-muted">
                              {machine.last_activity}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            );
          })
        : null}
    </div>
  );
};

// const Wrapper = styled.section`
//   font-family: var(--ff-primary);
//   background-color: var(--clr-primary-11);
//   border-radius: 8px;
//   color: var(--clr-black-1);
//   text-align: center;
//   margin: auto 0;
//   padding: 3px;
//   p {
//     margin: 5px 0;
//   }
//   margin-bottom: 10px;
//   box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 8px;
// `;
export default BatchWiseMachines;
