
const ReportViewComponent = ({graph_view,changeView}) => {

  const activeStyle={backgroundColor:"#4040F2" , color:"white", padding:"8px 20px 0px 20px", cursor:"pointer"};
  const inactiveStyle={backgroundColor:"#fff" , color:"#D6DADE", padding:"8px 20px 0px 20px", cursor:"pointer"};

  return (
    <div style={floorStyle}>
      <div className="d-flex justify-content-between" style={{ maxWidth: "1100px", margin: "0 auto" }}>
        <div className="d-flex justify-content-start w-50 pt-3">
          <div className="border rounded-pill me-2 graph-table" style={{backgroundColor:"#4040F2", color:"white", padding:"8px 20px 0px 20px", cursor:"pointer"}}>View Machine Wise</div>
          <div className="border rounded-pill p-2 text-muted graph-table" style={{padding:"8px 20px 0px 20px" ,cursor:"pointer"}}> View Group Wise </div>
        </div>
        <div className="d-flex justify-content-end w-50 pt-3">
          <div className="border rounded-pill me-2 graph-table" style={graph_view? activeStyle : inactiveStyle} id='graph_view' onClick={changeView}>Graph View</div>
          <div className="border rounded-pill p-2 graph-table" style={!graph_view? activeStyle : inactiveStyle} id='table_view'  onClick={changeView}>Table View</div>
        </div>
        <div></div>
      </div>
    </div>
  );
};
const floorStyle = {
  backgroundColor: "#FFFFFF",
  height: "70px",
  width: "100%",
  // marginTop: "10px",
  // marginBottom: "10px",
  overflow: "hidden",
  fontSize: "13px",
  
  // fontWeight: "bold",
  //   position:"relative"
};
const listFloor = (display) => {
  return {
    position: "absolute",
    top: "130px",
    left:"520px",
    backgroundColor: "#FFFF",
    width: "230px",
    zIndex: "100000",
    borderRadius: "5px",
    // padding: "10px",
    border: "1px solid #DEE2E6",
    display: display,
  };
};
export default ReportViewComponent;
