import React, { useEffect, useState } from "react";
import AuthUser from "../components/AuthUser";
import Card from "../components/Card";
import { useUserContext } from "../context/user_context";
import { intervalTime } from "../utils/constants";

const MachineStatus = ()=>{
    const { selectedFactoryId } = useUserContext();
    const { user, http } = AuthUser();
    const [summary, setSummary] = useState([]);
    const fetchTotalMachineSummary = async () => {
        try {
          const { data } =
            user &&
            selectedFactoryId &&
            (await http.get(
              `/factory-machine-summary?factory_id=${selectedFactoryId}`
            ));
          setSummary(data);
        } catch (error) {}
      };
    useEffect(() => {
        fetchTotalMachineSummary();
        // fetchToDaysMachineSummary();
        const interval = setInterval(() => {
          fetchTotalMachineSummary();
        //   fetchToDaysMachineSummary();
        }, intervalTime);
    
        return () => clearInterval(interval);
      }, [selectedFactoryId]);
    return (
        <div  style={{ maxWidth: "1100px", margin: "0 auto" }}>
              <p className="ms-4 fw-bolder text-dark">Machine Status</p>
              <div className="row mb-4">
                <div className="col-md-6 col-lg-6 col-xl-3">
                  <Card
                    title="total machine"
                    amount={summary.total}
                    data={[{ name: 1, value: summary.total }]}
                    colors={["#44C13C", "#daf3d8"]}
                  />
                </div>
                <div className="col-md-6 col-lg-6 col-xl-3">
                  <Card
                    title="Active"
                    color="#07C17E"
                    amount={summary.active}
                    data={[
                      { name: 2, value: summary.active },
                      { name: 1, value: summary.total },
                    ]}
                    colors={["#3C61C1", "#C1D6FF"]}
                  />
                </div>
                <div className="col-md-6 col-lg-6 col-xl-3">
                  <Card
                    title="idle"
                    color="#FEC400"
                    amount={summary.idle}
                    data={[
                      { name: 2, value: summary.idle },
                      { name: 1, value: summary.total },
                    ]}
                    colors={["#C13C3C", "#FBD9D9"]}
                  />
                </div>
                <div className="col-md-6 col-lg-6 col-xl-3">
                  <Card
                    title="off-line"
                    color="#E0125A"
                    amount={summary.offline}
                    data={[
                      { name: 2, value: summary.offline },
                      { name: 1, value: summary.total },
                    ]}
                    colors={["#FEC400", "#FBF8D9"]}
                  />
                </div>
              </div>
            </div>
    )
}
export default MachineStatus;