import React from "react";
import { Cell, Pie, PieChart } from "recharts";

const PieChartCustom = ({
  title,
  amount,
  color,
  children,
  className,
  data,
  colors,
}) => {
  // console.log("colors:",colors, data)
  return (
    <div className={`chart-container ${className}`}>
      <PieChart width={180} height={180}>
        <Pie
          data={data}
          cx={100}
          cy={100}
          innerRadius={60}
          outerRadius={75}
          fill="#8884d8"
          paddingAngle={4}
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Pie>
        {/* <img
          src={status1}
          alt="My Image"
          style={{
            position: "absolute",
            top: "50%",
            // left: "50%",
            // transform: "translate(-50%, -50%)",
            // width: "50%",
          }}
        /> */}
      </PieChart>
    </div>
  );
};

export default PieChartCustom;
