export const links = [
    {
        id:1,
        text:'overview',
        url:'/'
    },
    {
        id:2,
        text:'live',
        url:'/live'
    },
    {
        id:3,
        text:'reports',
        url:'/reports'
    },
]

// base url 
// issues with http > https
// export const URL = 'http://127.0.0.1:8000/api';
export const URL = 'https://factorynext-back-stag.arthor.com.bd/api';

export const URL_FOR_PROTECTED_APIs = `${URL}/v2`;

export const intervalTime = 10000;