import React from 'react';
import styled from 'styled-components';
const SingleMachineTableInfo = ({ summary,machineDetails,machineId,deviceId }) => {

  return <Wrapper>
      <table className="table table-borderless">
        <tbody>
          <tr>
            <td className='text-md-end'><p className='text-dark'>machine id : </p></td>
            <td className='text-md-start'><p className='value text-dark'>{`${machineId}(${deviceId})`}</p></td>
          </tr>

          <tr>
            <td className='text-md-end'><p className='text-dark'>machine type : </p></td>
            <td className='text-md-start'><p className='value text-dark'>{machineDetails.machineType}</p></td>
          </tr>

          <tr>
            <td className='text-md-end'><p className='text-dark'>Floor : </p></td>
            <td className='text-md-start'><p className='value text-dark'>{machineDetails.floor}</p></td>
          </tr>

          <tr>
            <td className='text-md-end'><p className='text-dark'>total interrupts : </p></td>
            <td className='text-md-start'><p className='value text-dark'>{summary.totalInterrupt} times</p></td>
          </tr>

        </tbody>
      </table>
    
  </Wrapper>
};

const Wrapper = styled.section`
.table{
  line-height:1px;
  background-color:#FFFFFF ;
  height:170px;
  padding:10% !important;
  border-radius:var(--radius) ;
  p{
    margin:5px ;
    white-space:wrap ;
  };
    
  box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 8px;
}


td{
  color:var(--clr-primary-10);
}

p.value{
  font-weight:normal ;
  color:#E1EA81;
}

@media screen and (max-width:575px){
  p{
    font-size:11px;
  }
}
`
export default SingleMachineTableInfo;
