import React from "react";
import { Link } from "react-router-dom";
import { Dropdown, Menu } from "semantic-ui-react";

const MenuDropDown = () => (
    <div style={{ position: "absolute", top: "50px", zIndex: "9999" }}>
    <Menu vertical>
      <Menu.Item>Home</Menu.Item>
      <Dropdown text="Machines" pointing="left" className="link item">
        <Dropdown.Menu>
          <Dropdown.Item><Link to='/'>Overview</Link></Dropdown.Item>
          <Dropdown.Item><Link to='/live'>Floor Map</Link></Dropdown.Item>
          <Dropdown.Item><Link to='/reports'>Reports</Link></Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Menu.Item>Browse</Menu.Item>
      <Menu.Item>Help</Menu.Item>
    </Menu>
  </div>
);

export default MenuDropDown;
